import rh from "../assets/rh.png";
import tf from "../assets/tylkofans.webp";
function Career({lang, event}) {

    let locale = lang.work;
    return ( 
        <>
            <div className="career-block" id="apply">
                <div className="career-screen-bg"></div>
                <div className="container">
                    <div className="flex-items">
                        <h1 dangerouslySetInnerHTML={{ __html: locale.heading }} />
                        <div className="flex-links-container">
                            <h2>{locale.aff}</h2>
                            <div className="flex-links">
                                <a href="https://rhodiumsites.com" target="_blank">
                                    <img src={rh} alt="Rhodiumsites" />
                                    <span className="to-link">rhodiumsites.com</span>
                                </a>
                                {/*}
                                <a href="https://tylkofans.pl" target="_blank">
                                    <img src={tf} alt="Tylkofans.pl" />
                                    <p className="to-link">tylkofans.pl</p>
                                </a>
                                {*/}
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div>
                            <h2>{locale.element1}</h2>
                            <p>{locale.element2}</p>
                        </div>
                        <button className="btn-form" onClick={event}>{locale.element3}</button>
                    </div>
                    <div className="flex-links-container dp-flex-onsmall">
                        <h2>Affiliates</h2>
                        <div className="flex-links">
                            <a href="https://rhodiumsites.com" target="_blank">
                                <img src={rh} alt="Rhodiumsites" />
                                <p className="to-link">rhodiumsites.com</p>
                            </a>
                            {/*}
                            <a href="https://tylkofans.pl" target="_blank">
                                <img src={tf} alt="Tylkofans.pl" />
                                <p className="to-link">tylkofans.pl</p>
                            </a>
                            {*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
     );
}

export default Career;